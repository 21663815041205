import * as $ from "jquery";
import * as htmx from "htmx.org";
import "bootstrap/js/dist/modal";
import { addEventDelegate, appendHtml, htmlToDocumentFragment, prependHtml, toggleClass } from "./dom";
import { isMob } from "./util";
import { newUuid } from "./uuid";
import { closeAllPopoversExcept } from "./popover";

/**
 * @typedef {object} Dialog
 * @property {string} [title]
 * @property {string} content
 * @property {string} [submitButtonText='OK']
 * @property {string} [size='lg']
 * @property {() => void} [onSubmit]
 * @property {() => void} [onShown]
 * @property {() => void} [onClosed]
 * @property {boolean} [pinToBottom=false]
 * @property {string} [classes]
 * @property {string} [bodyClasses]
 * @property {boolean} [showHeader=true]
 * @property {boolean} [showFooter=true]
 * @property {boolean} [canCloseByClickingOutside=false]
 * @property {string} [modal='asModal'] modal window selector
 * @property {string} [container]
 * @property {boolean} [fade=true]
 * @property {boolean} [noBackdrop=false]
 */

/**
 * @typedef {object} AlertOptions
 * @property {string} [type]
 * @property {boolean} [append]
 * @property {boolean} [prepend]
 * @property {string} [title]
 * @property {boolean} [fullscreen]
 * @property {() => void} [callback]
 * @property {() => void} [closeCallback]
 * @property {boolean} [pinToBottom]
 */

/**
 * @typedef {object} ConfirmOptions
 * @property {string} [okText]
 * @property {string} [cancelText]
 * @property {string} [text]
 * @property {() => void} [showCallback]
 * @property {boolean} [autoClose]
 * @property {string} [title]
 * @property {(result: boolean, resolve: () => void) => void} [callback] второй аргумент нужен только чтобы корректно завершить promise после закрытия
 */

/**
 * @typedef {object} ModalOptions
 * @property {string} [id]
 * @property {string} [classes='']
 * @property {string} [bodyClasses='']
 * @property {boolean} [showHeader=true]
 * @property {boolean} [showFooter=true]
 * @property {string} [submitButtonText='OK']
 * @property {string} [container='body']
 * @property {boolean} [fade=true]
 * @property {boolean} [noBackdrop=false]
 */

let dialogHotkeyInitialized = false;

/** @type {NodeJS.Timeout} */
let alertTimeout = null;

/**
 * 
 * @param {ModalOptions} param0 
 */
function initModal({ id, classes = '', bodyClasses = '', showHeader = true, showFooter = true, submitButtonText = 'OK', container = 'body', fade = true, noBackdrop = false }) {
    appendHtml(container,
        `<div class="modal ${fade ? 'fade' : ''} ${classes}" id="${id}" aria-labelledby="${id}-title" ${noBackdrop ? `data-backdrop="false"` : ''}>
            <div class="modal-upper-filler"></div>
            <div class="modal-dialog">
                <div class="modal-handle">
                    <div class="modal-handle-line"></div>
                </div>
                <div class="modal-content">
                    ${
                        !showHeader ? '' :
                        `<div class="modal-header no-font-scaling-inside">
                            <span class="modal-title" id="${id}-title"></span>
                            <button class="close" data-dismiss="modal">&times;</button>
                        </div>`
                    }
                    <div class="modal-body no-font-scaling-inside ${bodyClasses}"></div>
                    ${
                        !showFooter ? '' :
                        `<div class="modal-footer no-font-scaling-inside">
                            <button class="btn btn-default" data-dismiss="modal">Закрыть</button>
                            ${
                                !submitButtonText ? '' :
                                `<button class="btn btn-primary">${submitButtonText}</button>`
                            }
                        </div>`
                    }
                </div>
            </div>
        </div>`
    );
}

/**
 * 
 * @param {Dialog} param0 
 */
function showDialog({ 
        title, content, submitButtonText = 'OK', size = 'lg', onSubmit, onShown, onClosed, modal = 'asModal', 
        pinToBottom = false, classes, bodyClasses, showFooter = true, showHeader = true, canCloseByClickingOutside = false,
        container = 'body', fade = true, noBackdrop = false
    }) {
    if (modal == 'asModal' && pinToBottom) modal = "bottom-modal";
    document.getElementById(modal)?.remove();
    initModal({ id: modal, classes, bodyClasses, showFooter: showFooter && !pinToBottom, showHeader, submitButtonText, container, fade, noBackdrop });

    let $modal = document.getElementById(modal);
    let visibleModal = document.querySelector('.modal.show');
    if (visibleModal) {
        $modal.style.zIndex = (parseInt(visibleModal.style.zIndex) + 5).toString();
    }

    let $title = $modal.querySelector('.modal-title');
    if ($title) $title.innerHTML = title || '';
    $modal.querySelector('.modal-body').innerHTML = content || '';

    let submitBtn = $modal.querySelector('.modal-footer .btn-primary');
    if (onSubmit) submitBtn.addEventListener('click', onSubmit);
    if (onClosed) {
        $(`#${modal}`).on('hidden.bs.modal', onClosed);
    }

    let sizes = ['lg', 'xl', 'fullscreen'];
    if (sizes.includes(size)) toggleClass($modal.querySelector('.modal-dialog'), `modal-${size}`, true);
    toggleClass($modal, "pin-to-bottom", pinToBottom);

    $($modal).modal({ show: true, backdrop: canCloseByClickingOutside || pinToBottom ? true : 'static' });

    // Подключаем Ctrl + S к главной кнопке
    if (!dialogHotkeyInitialized) {
        dialogHotkeyInitialized = true;
        document.querySelector('body').addEventListener('keydown', function (e) {
            if (document.querySelector(`#${modal}.show`) && e.ctrlKey && e.which == 83) {
                e.preventDefault();
                document.querySelector(`#${modal} .btn-primary`).dispatchEvent(new MouseEvent("click", { view: window, bubbles: true, cancelable: true }));
            }
        });
    }
    
    $(`#${modal}`).on('shown.bs.modal', e => {
        htmx?.process(e.target);
    });

    if (onShown) {
        $(`#${modal}`).on('shown.bs.modal', onShown);
    }
}

/**
 * 
 * @param {string} modal 
 */
function closeDialog (modal = '#asModal') {
    $(modal).modal('hide');
}

/**
 * 
 * @param {string} modal 
 */
function showModal (modal = '#asModal') {
    $(modal).modal('show');
}

function initSwipe() {
    if (!isMob()) return;

    let touchstartY = 0;
	let touchendY = 0;

    function handleGesture() {
        if (touchstartY && touchendY - touchstartY >= 40) {
            closeDialog('.modal.pin-to-bottom');
        }

        touchstartY = 0;
        touchendY = 0;
    }

    $(document).on('touchstart', '.modal.pin-to-bottom .modal-handle, .modal.pin-to-bottom .modal-handle-custom, .modal.pin-to-bottom .modal-header', function(event) {
        event.preventDefault();
        event.stopPropagation();
        touchstartY = event.changedTouches[0].screenY;

        /** @type {HTMLElement} */
        let modal = this.closest('.modal');
        modal.classList.add('swipe-active')
    });
    
    $(document).on('touchend', '.modal.pin-to-bottom .modal-dialog', function(event) {
        event.stopPropagation();
        touchendY = event.changedTouches[0].screenY;
        handleGesture();

        /** @type {HTMLElement} */
        let modal = this.closest('.modal');
        modal.classList.remove('swipe-active')
    }); 

    $(document).on('touchmove', '.modal.pin-to-bottom .modal-dialog', function(e) {
        e.stopPropagation();

        var xStart, yStart = 0;
    
        xStart = e.touches[0].screenX;
        yStart = e.touches[0].screenY;
    
        var xMovement = Math.abs(e.touches[0].screenX - xStart);
        var yMovement = Math.abs(e.touches[0].screenY - yStart);

        if ((xMovement * 3) > yMovement) {
            e.preventDefault();
        }
    });
}

function initDialogs() {
    initSwipe();

    $(document).on('hidden.bs.modal', '.modal.pin-to-bottom:not(.persist)', function(){
        $(this).remove();
    });

    $(document).on('hidden.bs.modal', '.modal.pin-to-bottom', function(){
        $(this).closest('.modal-container').addClass('d-none');
    });

    $(document).on('show.bs.modal', '.modal.pin-to-bottom', function(){
        $(this).closest('.modal-container').removeClass('d-none');
    });

    $(document).on('show.bs.modal', () => {
        closeAllPopoversExcept(null);
    });

    
    $(document).on('hidden.bs.modal', function(){
        //фикс для случая, когда быстро открывается-закрывается несколько диалогов и застревает темный фон
        if (!document.querySelector('.modal.show')) {
            document.querySelector('.modal-backdrop')?.remove();
        }

        if (document.querySelector('.modal.show')) {
            //если после закрытия модалки оказывается, что открылась другая модалка, то не удаляем .modal-open с body
            document.body.classList.add('modal-open');
        }
    });
}

function isCabinet() {
    return location.pathname.toLowerCase().startsWith('/admin') || location.pathname.toLowerCase().startsWith('/client')
}

/**
 * 
 * @param {string} msg 
 * @param {AlertOptions} options 
 * @returns 
 */
function showAlert(msg, options) {
    if (!msg) return;

    options = Object.assign({}, { type: "info", append: true, fullscreen: false, pinToBottom: false }, options);

    if (options.prepend) {
        options.append = false;
    }

    let classes = {
        info: "",
        danger: "tum-dark-red",
        warning: "tum-dark-red",
        success: "tum-green"
    };

    let alreadyShown = !!document.querySelector("#asModal.show");
    let html = `<div class="tum-alert-text ${classes[options.type] || ''}">${msg}</div>`;
    if (alreadyShown) {
        if (!document.querySelector("#asModal .tum-alerts-content")) {
            appendHtml("#asModal .modal-body", "<div class='tum-alerts-content'></div>");
        }

        if (!options.append && !options.prepend && document.querySelector("#asModal .tum-alert-text")) {
            document.querySelectorAll("#asModal .tum-alert-text").forEach(e => e.remove());
        }

        if (options.prepend) {
            prependHtml("#asModal .tum-alerts-content", html);
        }
        else {
            appendHtml("#asModal .tum-alerts-content", html);
        }

        clearTimeout(alertTimeout);
    }
    else {
        showDialog({
            title: options.title || "Информация",
            content: "<div class='tum-alerts-content'>" + html + "</div>",
            pinToBottom: options.pinToBottom
        });

        if (!isCabinet()) {
            let backdrop = [...document.querySelectorAll(".modal-backdrop")].slice(-1)[0];
            if (backdrop) backdrop.style.zIndex = '3000';
        }

        if (options.type == "success") {
            alertTimeout = setTimeout(function () {
                closeDialog();
            }, 2000);
        }
    }

    if ((!options.append && !options.prepend) || !alreadyShown) {
        document.querySelector("#asModal .modal-footer").innerHTML = `<button type="button" class="btn btn-default" data-dismiss="modal">Закрыть</button>`;

        document.querySelector("#asModal .modal-title").innerHTML = options.title || "Информация";

        toggleClass("#asModal .modal-footer", "d-none", false);
        toggleClass("#asModal [data-dismiss=modal]", "d-none", false);

        let dialog = document.querySelector("#asModal .modal-dialog");
        toggleClass(dialog, "modal-full", options.fullscreen);
        toggleClass(dialog, "modal-danger", options.type == "danger2");
        toggleClass(dialog, "modal-success", options.type == "success2");
    }

    if (options.callback) {
        options.callback();
    }

    if (options.closeCallback) {
        document.querySelector('#asModal .btn-default').addEventListener('click', options.closeCallback);
    }
}

/**
 * функция возвращает promise, но then будет работать только в случае, когда кнопки ок/отмена сразу закрывают модалки
 * если при клике на "ок" модалка может не закрыться сразу из-за какой-то валидации, то вместо then
 * надо использовать callback
 * @param {ConfirmOptions} options 
 * @returns {Promise<boolean>}
 */
function confirmAsync(options) {
    let defaults = {
        okText: 'Ок',
        cancelText: 'Отмена',
        text: 'Продолжить?',
        autoClose: true,
        title: 'Подтверждение'
    };

    options = Object.assign({}, defaults, options);

    return new Promise(resolve => {
        showAlert(
            options.text, {
            title: options.title,
            append: false,
            callback: () => {
                toggleClass("#asModal [data-dismiss=modal]", "d-none", true);
                let okButtonHtml = `<button type="button" class="btn btn-primary" data-dismiss="${options.autoClose ? 'modal' : ''}">${options.okText}</button>`;
                let cancelButtonHtml = `<button type="button" class="btn btn-default" data-dismiss="modal">${options.cancelText}</button>`;
                let okButton = htmlToDocumentFragment(okButtonHtml).firstChild;
                let cancelButton = htmlToDocumentFragment(cancelButtonHtml).firstChild;
                let footer = document.querySelector("#asModal .modal-footer");
                footer.append(okButton, cancelButton);
                okButton.addEventListener('click', () => options.callback ? options.callback(true, () => resolve(true)) : resolve(true));
                cancelButton.addEventListener('click', () => options.callback ? options.callback(false, () => resolve(false)) : resolve(false));
                if (options.showCallback) options.showCallback();
            }
        });
    });
}

function initTuModal(){
    addEventDelegate(document, 'click', '.tu-modal', element => {
        let id = element.dataset.modalId || `modal-${newUuid()}`;

        showDialog({
            content: document.querySelector(element.dataset.contenttarget)?.innerHTML || element.dataset.content,
            canCloseByClickingOutside: false,
            modal: id,
            title: element.dataset.title,
            showHeader: !!element.dataset.title,
            size: element.dataset.size,
            submitButtonText: null,
            pinToBottom: isMob() && element.dataset.mobModal != '',
            onClosed: () => {
                document.getElementById(id).remove();
            }
        });
    });
}

export { showDialog, closeDialog, showModal, showAlert, confirmAsync, initDialogs, initTuModal };
